<div class="container-fluid d-flex align-items-center p-0">
  <div class="row row-cols-1 row-cols-lg-2 w-100 m-0 g-0 flex-grow-1 vh-100">
    <!-- Left Side -->
    <div
      class="col col-lg-8 d-flex flex-column align-items-center justify-content-center text-center bg-light py-5 px-3"
    >
      <img
        src="assets/images/logo.png"
        alt="Aldismed Logo"
        class="mb-4 img-fluid"
        style="max-width: 120px"
      />
      <p class="text-muted mx-auto" style="max-width: 500px">
        Lorem ipsum dolor sit amet consectetur. Facilisis rhoncus mi ultricies
        ut. Nec erat lacus id auctor. Euismod dictumst porttitor cursus.
      </p>
      <ul class="list-unstyled mt-3">
        <li class="d-flex align-items-center mb-2">
          <i class="bi bi-check-circle-fill text-primary me-2"></i> Add a blog
        </li>
        <li class="d-flex align-items-center">
          <i class="bi bi-check-circle-fill text-primary me-2"></i> Add a
          product
        </li>
      </ul>
    </div>

    <!-- Right Side (Login Form) -->
    <div
      class="col col-lg-4 d-flex flex-column align-items-center justify-content-center bg-primary text-white p-4"
    >
      <h2 class="mb-4 fs-4 text-center">Welcome!</h2>
      <form class="w-100 px-3" (ngSubmit)="onLogin()" style="max-width: 300px">
        <div class="form-group mb-3">
          <label for="username" class="form-label small">User name</label>
          <input
            type="text"
            class="form-control"
            id="username"
            [(ngModel)]="username"
            name="username"
            placeholder="Enter your username"
          />
        </div>
        <div class="form-group mb-4">
          <label for="password" class="form-label small">Password</label>
          <input
            type="password"
            class="form-control"
            id="password"
            [(ngModel)]="password"
            name="password"
            placeholder="Enter your password"
          />
        </div>
        <button type="submit" class="btn btn-teal w-100">LOGIN</button>
      </form>
    </div>
  </div>
</div>
