// src/app/auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedIn = false;

  // Getter for login status
  get loginStatus(): boolean {
    return this.isLoggedIn;
  }

  // Setter for login status
  set loginStatus(status: boolean) {
    this.isLoggedIn = status;
  }
}
