<div class="breadcrumb-wrapper">
  <div class="max-width-container">
    <div *ngIf="showBreadcrumb">
      <nav class="nav-style" *ngIf="breadcrumbs.length > 0" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li *ngFor="let breadcrumb of breadcrumbs; let last = last" 
              class="breadcrumb-item" 
              [class.active]="last">
            <a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
            <span *ngIf="last">{{ breadcrumb.label }}</span>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</div>
