import { createSelector } from '@ngrx/store';
import { AppState } from '../../../core/models/store-typings.model';

const productPage = (state: AppState) => state.productPage;

export const productListQuery = createSelector(
  productPage,
  (product) => product.ProductList
);
export const productDetailQuery = createSelector(
  productPage,
  (product) => product.ProductDetails
);
export const productTypeListQuery = createSelector(
  productPage,
  (product) => product.TypeList
);

export const productQueries = {
  productDetailQuery,
  productListQuery,
  productTypeListQuery,
};
