<div class="">
  <section class="cta-section">
    <h2>{{ "Slogans1" | translate }}</h2>
    <p>{{ "Slogans4" | translate }}</p>
    <button class="cta-button" routerLink="/contact">
      {{ "contact_us" | translate }}
    </button>
  </section>

  <footer>
    <div class="footer-top max-width-container">
      <div class="footer-left">
        <img class="pe-2 mb-4" src="assets/images/aldismed.svg" alt="Aldismed" />
        <p>{{ "Slogans2" | translate }}</p>
      </div>
      <div class="footer-links">
        <div class="company-products mt-0">
          <div class="company">
            <h4>{{ "Company" | translate }}</h4>
            <ul>
              <li>
                <a routerLink="/about">{{ "abouts" | translate }}</a>
              </li>
              <li>
                <a routerLink="/contact">{{ "contacts" | translate }}</a>
              </li>
              <li>
                <a routerLink="/blog">{{ "blogs" | translate }}</a>
              </li>
            </ul>
          </div>
          <div class="products">
            <h4>{{ "products" | translate }}</h4>
            <ul>
              <li>
                <a>{{ "Compresses" | translate }}</a>
              </li>
              <li>
                <a>{{ "Bandages" | translate }}</a>
              </li>
              <li>
                <a>{{ "spa_dev" | translate }} aids</a>
              </li>
              <li>
                <a>{{ "pan_pat" | translate }}</a>
              </li>
              <li>
                <a>{{ "con_chg" | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="social-media">
          <h4>{{ "Social_Media" | translate }}</h4>
          <ul>
            <li>
              <a
                href="https://www.instagram.com/sarl_aldismed/"
                target="_blank"
                >{{ "Instagram" | translate }}</a
              >
            </li>
            <li>
              <a href="https://www.facebook.com/aldismed" target="_blank">{{
                "Facebook" | translate
              }}</a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/aldismed/"
                target="_blank"
                >{{ "LinkedIn" | translate }}</a
              >
            </li>
            <li>
              <a href="https://www.youtube.com/@SarlAldismed" target="_blank">{{
                "YouTube" | translate
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-bottom max-width-container">
      <p class="text-secondary">&copy; {{ "Copyright" | translate }}</p>
      <div class="footer-terms">
        <span class="text-secondary">{{
          "Terms_of_Condition" | translate
        }}</span>
        <span class="text-secondary">{{ "Privacy_Policy" | translate }}</span>
      </div>
    </div>
  </footer>
</div>
