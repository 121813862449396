import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../../auth.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule,RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  constructor(private authService: AuthService, private router: Router) {}

  onLogout() {
    this.authService.loginStatus = false;
    this.router.navigate(['/home']);
  }
}
