import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { isPlatformBrowser } from '@angular/common';
import Quill from 'quill';

@Component({
  selector: 'app-add-blog',
  standalone: true,
  imports: [TranslateModule, SidebarComponent],
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.scss']
})
export class AddBlogComponent implements AfterViewInit {
  private Quill: any;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  async ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const { default: Quill } = await import('quill');
      this.Quill = Quill;

      const editor = new Quill('#editor', {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, 3, false] }],                
            [{ 'font': [] }],                                 
            [{ 'size': ['small', 'normal', 'large', 'huge'] }],  
            ['bold', 'italic', 'underline', 'strike'],       
            [{ 'color': [] }, { 'background': [] }],         
            [{ 'script': 'sub' }, { 'script': 'super' }],     
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],    
            [{ 'indent': '-1' }, { 'indent': '+1' }],        
            [{ 'align': [] }],                               
            ['blockquote', 'code-block'],                    
            ['link', 'image', 'video'],                      
            ['clean']                                         
          ]
        }
      });
    }
  }}
