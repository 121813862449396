import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-add-product',
  standalone: true,
  imports: [TranslateModule, SidebarComponent],
  templateUrl: './add-product.component.html',
  styleUrl: './add-product.component.scss'
})
export class AddProductComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  async ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const { default: Quill } = await import('quill');

      const editors = ['editor1', 'editor2', 'editor3'];
      editors.forEach(editorId => {
        new Quill(`#${editorId}`, {
          theme: 'snow',
          modules: {
            toolbar: [
              [{ 'header': [1, 2, 3, false] }],                 
              [{ 'font': [] }],                                 
              [{ 'size': ['small', 'normal', 'large', 'huge'] }], 
              ['bold', 'italic', 'underline', 'strike'],        
              [{ 'color': [] }, { 'background': [] }],         
              [{ 'script': 'sub' }, { 'script': 'super' }],     
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],    
              [{ 'indent': '-1' }, { 'indent': '+1' }],         
              [{ 'align': [] }],                               
              ['blockquote', 'code-block'],                     
              ['link', 'image', 'video'],                       
              ['clean']                                        
            ]
          }
        });
      }
    )}}
  }  
