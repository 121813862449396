import { Action, createReducer, on } from '@ngrx/store';
import { IBlogPageState } from '../../../core/models/store-typings.model';
import {
  blogListFetchedAction,
  blogAddedAction,
  blogUpdatedAction,
  blogDeletedAction,
} from './blog.actions';

export const blogInitialState: IBlogPageState = {
  blogDetails: {
    DataAR: { description: '', title: '' },
    DataEN: { description: '', title: '' },
    DataFR: { description: '', title: '' },
    id: '',
    postDate: '',
  },
  bloglist: [],
};

const BlogPageReducer = createReducer(
  blogInitialState,
  on(blogListFetchedAction, (state, { payload }) => ({
    ...state,
    bloglist: payload,
  })),
  on(blogAddedAction, (state, { payload }) => ({
    ...state,
    bloglist: [...state.bloglist, payload],
  })),
  on(blogUpdatedAction, (state, { payload }) => ({
    ...state,
    bloglist: state.bloglist.map((blog) =>
      blog.id === payload.id ? payload : blog
    ),
  })),
  on(blogDeletedAction, (state, { payload }) => ({
    ...state,
    bloglist: state.bloglist.filter((blog) => blog.id !== payload),
  }))
);

export function blogPageRootReducer(
  state: IBlogPageState | undefined,
  action: Action
) {
  return BlogPageReducer(state, action);
}
