import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import e from 'express';

@Injectable({
  providedIn: 'root',
})
export class FontService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  applyGlobalFontClasses(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Always reapply global font classes
    const descriptionElements = this.document.querySelectorAll('p, span, a');
    descriptionElements.forEach((el) => el.classList.add('description-font'));

    const titleElements = this.document.querySelectorAll(
      'h1, h2, h3, h4, h5, h6'
    );
    titleElements.forEach((el) => el.classList.add('title-font'));
  }

  reapplyFontClasses(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Reset and reapply the font classes
      this.removeFontClasses(); // Optional: Remove existing font classes first
      this.applyGlobalFontClasses();

    }
  }

  removeFontClasses(): void {
    if (isPlatformBrowser(this.platformId)) {
      const descriptionElements =
        this.document.querySelectorAll('.description-font');
      descriptionElements.forEach((el) =>
        el.classList.remove('description-font')
      );

      const titleElements = this.document.querySelectorAll('.title-font');
      titleElements.forEach((el) => el.classList.remove('title-font'));
    }
  }

  applyRtlFont(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.style.fontFamily = '"Tajawal", sans-serif';

      const titleFontElements = this.document.querySelectorAll('.title-font');
      const descriptionFontElements =
        this.document.querySelectorAll('.description-font');

      titleFontElements.forEach((el) => {
        (el as HTMLElement).style.fontFamily = '"Tajawal", sans-serif';
      });

      descriptionFontElements.forEach((el) => {
        (el as HTMLElement).style.fontFamily = '"Tajawal", sans-serif';
      });
      this.reapplyFontClasses()
    }
  }

  applyLtrFont(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.style.fontFamily = ''; // Reset to default font family

      const titleFontElements = this.document.querySelectorAll('.title-font');
      const descriptionFontElements =
        this.document.querySelectorAll('.description-font');

      titleFontElements.forEach((el) => {
        (el as HTMLElement).style.fontFamily = '"OpenSans", sans-serif'; // LTR font
      });

      descriptionFontElements.forEach((el) => {
        (el as HTMLElement).style.fontFamily = '"Lato", sans-serif'; // LTR font
      });
    }
    this.reapplyFontClasses()

  }
}
