import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BlogService } from '../services/blog.service';
import {
  getBlogListAction,
  blogListFetchedAction,
  addBlogAction,
  blogAddedAction,
  updateBlogAction,
  blogUpdatedAction,
  deleteBlogAction,
  blogDeletedAction,
} from './blog.actions';
import { catchError, EMPTY, map, mergeMap } from 'rxjs';
import { ErrorHandlerService } from '../../../core/services/error/errorHander.service';

@Injectable()
export class BlogEffects {
  private action$ = inject(Actions);
  private blogService = inject(BlogService);
  private errorHandler = inject(ErrorHandlerService);

  loadBlogList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getBlogListAction),
      mergeMap(() =>
        this.blogService.getBlogList().pipe(
          map((blogs) => blogListFetchedAction({ payload: blogs })),
          catchError((err) => {
            this.errorHandler.handleError('Load Blog List', err);
            return EMPTY;
          })
        )
      )
    )
  );

  addBlog$ = createEffect(() =>
    this.action$.pipe(
      ofType(addBlogAction),
      mergeMap((action) =>
        this.blogService.addBlogList(action.payload).pipe(
          map((newBlog) => blogAddedAction({ payload: newBlog })),
          catchError((err) => {
            this.errorHandler.handleError('Add Blog', err);
            return EMPTY;
          })
        )
      )
    )
  );

  updateBlog$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateBlogAction),
      mergeMap((action) =>
        this.blogService.updateBlogList(action.payload).pipe(
          map((updatedBlog) => blogUpdatedAction({ payload: updatedBlog })),
          catchError((err) => {
            this.errorHandler.handleError('Update Blog', err);
            return EMPTY;
          })
        )
      )
    )
  );

  deleteBlog$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteBlogAction),
      mergeMap((action) =>
        this.blogService.deleyeBlogList(action.payload).pipe(
          map(() => blogDeletedAction({ payload: action.payload })),
          catchError((err) => {
            this.errorHandler.handleError('Delete Blog', err);
            return EMPTY;
          })
        )
      )
    )
  );
}
