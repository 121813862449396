import {
  Injectable,
  TemplateRef,
  Type,
  ComponentRef,
  Injector,
  Inject,
} from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DialogRef } from './dialog-ref';
import { DOCUMENT } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  public dialogref?: DialogRef;
  closeOnBackdropClick = true;
  closeOnEsc = true;
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  showModal<T>(
    component: any | TemplateRef<any> | Type<any>,
    data?: any
  ): DialogRef<T> {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      panelClass: 'panelclass',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });
    const overlayRef = this.overlay.create(overlayConfig);
    const dialogRef = new DialogRef<T>(overlayRef);
    const injector = new PortalInjector(
      this.injector,
      new WeakMap([[DialogRef, dialogRef]])
    );
    const containerRef: ComponentRef<T> = overlayRef.attach(
      new ComponentPortal(component, null, injector)
    );
    if (data) Object.assign(containerRef.instance as {}, { ...data });
    this.dialogref = dialogRef;
    this.registerCloseListeners(overlayRef, dialogRef);

    return dialogRef;
  }

  close(res?: any) {
    this.dialogref?.closeModal(res);
  }
  public registerCloseListeners<T>(overlayRef: any, dialogRef: any) {
    if (this.closeOnBackdropClick) {
      overlayRef.backdropClick().subscribe(() => dialogRef.closeModal());
    }

    if (this.closeOnEsc) {
      fromEvent(this.document, 'keyup')
        .pipe(
          filter((event: any) => event.keyCode === 27),
          takeUntil(dialogRef.onClose)
        )
        .subscribe(() => dialogRef.closeModal());
    }
  }
}
