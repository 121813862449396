import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [TranslateModule, FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(private router: Router, private authService: AuthService) {}

  username: string = '';
  password: string = '';

  onLogin() {
    if (this.username === 'aldismed' && this.password === '123') {
      this.authService.loginStatus = true;  
      this.router.navigate(['/productlist']);
    } else {
      alert('Invalid username or password');
    }
  }
}
