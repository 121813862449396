import { createAction, props } from '@ngrx/store';
import { BlogAttributes } from '../../../core/models/typings.models';

export const getBlogListAction = createAction('[Blog Page][UI] Get Blog List');
export const blogListFetchedAction = createAction(
  '[Blog Page][UI] Blog List Fetched',
  props<{ payload: BlogAttributes[] }>()
);

export const addBlogAction = createAction(
  '[Blog Page][UI] Add Blog',
  props<{ payload: BlogAttributes }>()
);
export const blogAddedAction = createAction(
  '[Blog Page][UI] Blog Added',
  props<{ payload: BlogAttributes }>()
);

export const updateBlogAction = createAction(
  '[Blog Page][UI] Update Blog',
  props<{ payload: BlogAttributes }>()
);
export const blogUpdatedAction = createAction(
  '[Blog Page][UI] Blog Updated',
  props<{ payload: BlogAttributes }>()
);

export const deleteBlogAction = createAction(
  '[Blog Page][UI] Delete Blog',
  props<{ payload: string }>()
);
export const blogDeletedAction = createAction(
  '[Blog Page][UI] Blog Deleted',
  props<{ payload: string }>()
);
