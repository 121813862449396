<div class="list-group-item d-flex justify-content-between align-items-center">
    <div>
        <h5 class="mb-0">Lorem Ipsum Dolor sit</h5>
        <small class="text-muted">
            <span class="me-4">12.07.2024</span>
            <i class="bi bi-geo-alt-fill"></i> El Eulma
        </small>
    </div>
    <button class="btn btn-bg">
        Edit  <i class="bi bi-pencil-square" (click)="navigateToEditProduct()"></i> 
    </button>
</div>
