<div class="fade-in-left hero-section">
    <div class="overlay"></div>
    <div class="overlay-text">
        <h1>{{ "coming_soon" | translate }}</h1>
        <!-- <p>The right source of knowledge can be powerful for professional growth.</p> -->
    </div>
</div>

<!-- <div class="m-4" id="blog-list">
    <div class="row">
        <div class="col-md-4 mb-4 d-flex justify-content-center"
            *ngFor="let item of items | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
            <app-blog-item></app-blog-item>
        </div>
    </div>

    <div class="pagination-container">
        <pagination-controls (pageChange)="onPageChange($event)" [autoHide]="true" [directionLinks]="true"
            previousLabel="" nextLabel="">
        </pagination-controls>
    </div>
</div> -->