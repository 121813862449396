<div class="d-flex flex-column vh-100 p-3 sidebar bg-light">
    <h1 class="text-primary p-1">Aldismed</h1>

    <div class="flex-grow-1 overflow-auto">
        <ul class="nav nav-pills flex-column mb-auto mt-4">
            <li class="nav-item mb-3">
                <a class="btn btn-light text-start w-100 d-flex align-items-center" routerLink="/bloglist" routerLinkActive="active">
                    <i class="bi bi-journal-text me-3"></i> Blog List
                </a>
            </li>
            <li class="nav-item mb-3">
                <a class="btn btn-light text-start w-100 d-flex align-items-center" routerLink="/addblog" routerLinkActive="active">
                    <i class="bi bi-grid-3x3-gap me-3"></i> Add Blog
                </a>
            </li>
            <li class="nav-item mb-3">
                <a class="btn btn-light text-start w-100 d-flex align-items-center" routerLink="/productlist" routerLinkActive="active">
                    <i class="bi bi-card-list me-3"></i> Product List
                </a>
            </li>
            <li class="nav-item mb-3">
                <a class="btn btn-light text-start w-100 d-flex align-items-center" routerLink="/addproduct" routerLinkActive="active">
                    <i class="bi bi-grid-3x3 me-3"></i> Add Product
                </a>
            </li>
        </ul>
    </div>

    <a class="btn btn-red text-start w-100 d-flex align-items-center mt-auto p-3" (click)="onLogout()">
        <i class="bi bi-box-arrow-right me-3"></i> Logout
    </a>
</div>
