import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-links-and-products',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './links-and-products.component.html',
  styleUrl: './links-and-products.component.scss'
})
export class LinksAndProductsComponent {

}
