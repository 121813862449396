<div class="container my-5">
  <div class="text-center my-4">
    <h1 class="display-5 fw-bold text-primary">
      ALDISMED IN MAGHREB EXPO 2023
    </h1>
    <p class="text-muted">Posted on May 05, 2024</p>
  </div>

  <div class="row mb-5 content-section text-start">
    <div class="col-md-12">
      <img
        src="assets/images/blog-image.png"
        alt="Aldismed Product"
        class="img-fluid rounded mb-3"
      />
      <h1 class="fw-bold headers">Lorem ipsum dolor. Ipsum.</h1>
      <p class="mt-3">
        Lorem ipsum dolor sit amet consectetur. Ac odio condimentum nunc neque
        sed aliquet integer amet. Ut nunc in egestas pretium cursus at.
      </p>
    </div>
  </div>

  <div class="row mb-5 content-section text-start">
    <div class="col-md-12">
      <img
        src="assets/images/blog-image1.png"
        alt="Medical Mask"
        class="img-fluid rounded mb-3"
      />
      <h1 class="fw-bold headers">
        Lorem ipsum dolor sit amet consectetur. Tortor.
      </h1>
      <p>
        Lorem ipsum dolor sit amet consectetur. Neque laoreet dolor semper massa
        urna. Mauris pellentesque turpis aenean mauris...
      </p>
    </div>
  </div>

  <div class="row mb-5 content-section text-start">
    <div class="col-md-12">
      <img
        src="assets/images/blog-image1.png"
        alt="Syringe"
        class="img-fluid rounded mb-3"
      />
      <h1 class="fw-bold headers">
        Lorem ipsum dolor sit amet consectetur. Ac.
      </h1>
      <p>
        Lorem ipsum dolor sit amet consectetur. Quam urna egestas sit varius
        blandit quam morbi sem sit. Integer a sociis viveam urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        am urna egestas sit varius blandit quam morbi am urna egestas sit varius
        blandit quam morbi am urna egestas sit varius blandit quam morbi am urna
        egestas sit varius blandit quam morbi am urna egestas sit varius blandit
        quam morbi am urna egestas sit varius blandit quam morbi am urna egestas
        sit varius blandit quam morbi am urna egestas sit varius blandit quam
        morbi am urna egestas sit varius blandit quam morbi am urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        am urna egestas sit varius blandit quam morbi am urna egestas sit varius
        blandit quam morbi am urna egestas sit varius blandit quam morbi am urna
        egestas sit varius blandit quam morbi am urna egestas sit varius blandit
        quam morbi am urna egestas sit varius blandit quam morbi am urna egestas
        sit varius blandit quam morbi am urna egestas sit varius blandit quam
        morbi am urna egestas sit varius blandit quam morbi am urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        am urna egestas sit varius blandit quam morbi am urna egestas sit varius
        blandit quam morbi am urna egestas sit varius blandit quam morbi am urna
        egestas sit varius blandit quam morbi am urna egestas sit varius blandit
        quam morbi am urna egestas sit varius blandit quam morbi am urna egestas
        sit varius blandit quam morbi am urna egestas sit varius blandit quam
        morbi am urna egestas sit varius blandit quam morbi am urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        am urna egestas sit varius blandit quam morbi am urna egestas sit varius
        blandit quam morbi am urna egestas sit varius blandit quam morbi am urna
        egestas sit varius blandit quam morbi am urna egestas sit varius blandit
        quam morbi am urna egestas sit varius blandit quam morbi am urna egestas
        sit varius blandit quam morbi am urna egestas sit varius blandit quam
        morbi am urna egestas sit varius blandit quam morbi am urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        am urna egestas sit varius blandit quam morbi am urna egestas sit varius
        blandit quam morbi am urna egestas sit varius blandit quam morbi am urna
        egestas sit varius blandit quam morbi am urna egestas sit varius blandit
        quam morbi am urna egestas sit varius blandit quam morbi am urna egestas
        sit varius blandit quam morbi am urna egestas sit varius blandit quam
        morbi am urna egestas sit varius blandit quam morbi am urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        am urna egestas sit varius blandit quam morbi am urna egestas sit varius
        blandit quam morbi am urna egestas sit varius blandit quam morbi am urna
        egestas sit varius blandit quam morbi am urna egestas sit varius blandit
        quam morbi am urna egestas sit varius blandit quam morbi am urna egestas
        sit varius blandit quam morbi am urna egestas sit varius blandit quam
        morbi am urna egestas sit varius blandit quam morbi am urna egestas sit
        varius blandit quam morbi am urna egestas sit varius blandit quam morbi
        Lorem ipsum dolor sit amet consectetur. Quam urna egestas sit varius
        blandit quam morbi sem sit. Integer a sociis viverra... Lorem ipsum
        dolor sit amet consectetur. Quam urna egestas sit varius blandit quam
        morbi sem sit. Integer a sociis viverra... Lorem ipsum dolor sit amet
        consectetur. Quam urna egestas sit varius blandit quam morbi sem sit.
        Integer a sociis viverra...
      </p>
    </div>
  </div>
</div>

<div class="social-links position-fixed end-0 mt-5 pt-5 me-4 p-2">
  <a
    href="https://www.facebook.com/aldismed"
    class="text-secondary d-block mb-2 fs-5"
    ><i class="bi bi-facebook"></i
  ></a>
  <a
    a
    href="https://www.linkedin.com/company/aldismed/"
    class="text-secondary d-block mb-2 fs-5"
    ><i class="bi bi-linkedin"></i
  ></a>
  <a
    href="https://www.instagram.com/sarl_aldismed/"
    class="text-secondary d-block fs-5"
    ><i class="bi bi-instagram"></i
  ></a>
</div>
<div class="text-center mt-4 mb-4">
  <a href="/blog" class="btn btn-outline-custom">
    <span>&larr;</span> Back to Blogs
  </a>
</div>
