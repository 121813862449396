<div class="navbar-wrapper">
  <div class="">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light max-width-container"
    >
      <a class="navbar-brand" routerLink="/">
        <img src="assets/images/logo.png" alt="Logo" class="logo" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarContent"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarContent" #navbarContent>
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/about">{{
              "abouts" | translate
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/products">{{
              "products" | translate
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/blog">{{ "blogs" | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact">{{
              "contacts" | translate
            }}</a>
          </li>
        </ul>

        <button class="btn btn-primary my-2 contact-us" routerLink="/contact">
          {{ "contact_us" | translate }}
        </button>

        <div class="">
          <div class="dropdown">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              id="languageDropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentLanguage }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="languageDropdown">
              <li>
                <a class="dropdown-item" (click)="selectLanguage('EN')">EN</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="selectLanguage('FR')">FR</a>
              </li>
              <li>
                <a class="dropdown-item" (click)="selectLanguage('AR')">AR</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
