import { Injectable } from '@angular/core';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { ErrorModalComponent } from '../../../shared/error-modal/error-modal';
import { DialogRef } from '../../../shared/dialog/dialog-ref';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  constructor(private dialogService: DialogService) { }

  public handleError(
    title: string,
    message: string
  ) {
    // ): DialogRef<ErrorModalComponent> {
    console.error(` - An error occurred: ${title} : ${message}`);

    // return this.dialogService.showModal(ErrorModalComponent, {
    //   title,
    //   message,
    // });
  }
}
