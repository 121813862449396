import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
  withFetch,
} from '@angular/common/http';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { HomeComponent } from './modules/home/home.component';
import { AboutUsComponent } from './modules/about-us/about-us.component';
import { ProductsComponent } from './modules/products/products.component';
import { ContactComponent } from './modules/contact/contact.component';
import { BlogComponent } from './modules/blog/blog.component';
import { LinksAndProductsComponent } from './modules/links-and-products/links-and-products.component';
import { ProductDetailsComponent } from './modules/products/component/product-details/product-details.component';
import { BlogDetailsComponent } from './modules/blog/component/blog-details/blog-details.component';
import { LoginComponent } from './modules/login/login.component';
import { SubcontractingComponent } from './modules/subcontracting/subcontracting.component';
import { AddProductComponent } from './modules/dashboard/component/add-product/add-product.component';
import { AddBlogComponent } from './modules/dashboard/component/add-blog/add-blog.component';
import { EditBlogComponent } from './modules/dashboard/component/edit-blog/edit-blog.component';
import { EditProductComponent } from './modules/dashboard/component/edit-product/edit-product.component';
import { reducers } from '.';
import { EffectsModule } from '@ngrx/effects';
import { ProductsEffect } from './modules/products/store/product.effects';
import { BlogEffects } from './modules/blog/store/blog.effects';

// Translation loader factory function
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

// Define routes
export const routes = [
  { path: '', component: HomeComponent, data: { breadcrumb: 'HOME' } },
  {
    path: 'about',
    component: AboutUsComponent,
    data: { breadcrumb: 'ABOUT US' },
  },
  {
    path: 'products',
    component: ProductsComponent,
    data: { breadcrumb: 'PRODUCT' },
  },
  {
    path: 'blog',
    component: BlogComponent,
    data: { breadcrumb: 'BLOG' },
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { breadcrumb: 'CONTACT' },
  },
  { path: 'login', component: LoginComponent, data: { breadcrumb: 'LOGIN' } },
  {
    path: 'blog/:id',
    component: BlogDetailsComponent,
    data: { breadcrumb: 'BLOG DETAILS' },
  },
  {
    path: 'products/:id',
    component: ProductDetailsComponent,
    data: { breadcrumb: 'PRODUCT DETAILS' },
  },
  {
    path: 'subcontracting',
    component: SubcontractingComponent,
    data: { breadcrumb: 'SUBCONTRACTING' },
  },
  {
    path: 'addproduct',
    component: AddProductComponent,
    data: { breadcrumb: 'ADDPRODUCT' },
  },
  {
    path: 'addblog',
    component: AddBlogComponent,
    data: { breadcrumb: 'ADDBLOG' },
  },
  {
    path: 'bloglist',
    component: EditBlogComponent,
    data: { breadcrumb: 'BLOGLIST' },
  },
  {
    path: 'productlist',
    component: EditProductComponent,
    data: { breadcrumb: 'PRODUCTLIST' },
  },
];

// ApplicationConfig
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature),

    // HTTP Client with interceptors
    provideHttpClient(withInterceptorsFromDi(), withFetch()),

    // NgRx Store configuration
    importProvidersFrom(
      StoreModule.forRoot(reducers, {
        runtimeChecks: {
          strictStateImmutability: true,
        },
      })
    ),
    importProvidersFrom(EffectsModule.forRoot([ProductsEffect, BlogEffects])),
    // Translation module configuration
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),

    // Other libraries as providers
    importProvidersFrom(NgxPaginationModule),

    // Import standalone components
    HomeComponent,
    AboutUsComponent,
    ProductsComponent,
    ContactComponent,
    BlogComponent,
    LinksAndProductsComponent,
    ProductDetailsComponent,
    BlogDetailsComponent,
    LoginComponent,
    SubcontractingComponent,
  ],
};
