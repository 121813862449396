import { Injectable, inject } from '@angular/core';
import { ErrorHandlerService } from '../../../core/services/error/errorHander.service';
import { ProductService } from '../services/product.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, EMPTY, switchMap } from 'rxjs';
import {
  getProductListAction,
  productListFetchedAction,
  getSelectedProductAction,
  productDetailsFetchedAction,
  getProductTypeListAction,
  productTypeListFetchedAction,
  addProductAction,
  productAddedAction,
  updateProductAction,
  productUpdatedAction,
  deleteProductAction,
  productDeletedAction,
} from './product.actions';

@Injectable({ providedIn: 'root' })
export class ProductsEffect {
  private action$ = inject(Actions);
  private productService = inject(ProductService);
  private errorHandler = inject(ErrorHandlerService);

  loadProductList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getProductListAction),
      switchMap(() =>
        this.productService.getProductList().pipe(
          map((res) => productListFetchedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Product List', err);
            return EMPTY;
          })
        )
      )
    )
  );

  loadProductDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(getSelectedProductAction),
      mergeMap((action) =>
        this.productService.getProductDetails(action.payload).pipe(
          map((res) => productDetailsFetchedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Product Details', err);
            return EMPTY;
          })
        )
      )
    )
  );

  loadProductTypeList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getProductTypeListAction),
      mergeMap(() =>
        this.productService.getProductTypeList().pipe(
          map((res) => productTypeListFetchedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Product Type List', err);
            return EMPTY;
          })
        )
      )
    )
  );

  addProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(addProductAction),
      mergeMap((action) =>
        this.productService.addProductList(action.payload).pipe(
          map((res) => productAddedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Add Product', err);
            return EMPTY;
          })
        )
      )
    )
  );

  updateProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateProductAction),
      mergeMap((action) =>
        this.productService.updateProductList(action.payload).pipe(
          map((res) => productUpdatedAction({ payload: res })),
          catchError((err) => {
            this.errorHandler.handleError('Update Product', err);
            return EMPTY;
          })
        )
      )
    )
  );

  deleteProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteProductAction),
      mergeMap((action) =>
        this.productService.deleteProductList(action.payload).pipe(
          map(() => productDeletedAction({ payload: action.payload })),
          catchError((err) => {
            this.errorHandler.handleError('Delete Product', err);
            return EMPTY;
          })
        )
      )
    )
  );
}
