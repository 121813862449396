<div class="d-flex vh-100">
  <!-- Sidebar Component -->
  <app-sidebar class="sidebar"></app-sidebar>

  <!-- Main Content Area -->
  <div class="flex-grow-1 overflow-auto p-4 main-content">
    <div class="card p-4">
          <h2 class="mb-4">Add Blog</h2>
          <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
              <li class="nav-item">
                  <a class="nav-link active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab">Français</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab">Anglais</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab">Arab</a>
              </li>
          </ul>
          <form>
            <div class="mb-3 row g-3">
                <div class="col-md-3">
                    <label for="productName" class="form-label">Titre</label>
                    <input type="text" class="form-control" id="Titre" placeholder="Titre de blog">
                </div>
                
                <div class="col-md-3">
                    <label for="uploadCover" class="form-label">Upload cover</label>
                    <div class="input-group">
                        <input type="file" class="form-control" id="uploadCover" accept="image/*">
                        <span class="input-group-text"><i class="bi bi-upload"></i></span>
                    </div>
                </div>
                
                <div class="col-md-3">
                    <label for="authorName" class="form-label">Author name</label>
                    <input type="text" class="form-control" id="authorName" placeholder="Enter author name">
                </div>
            </div>
            
            
            <div class="mb-4">
                <label for="editor" class="form-label-title mb-2">Text</label>
                <div id="editor" style="height: 300px; background-color: #fff;"></div>
            </div>
            
              <div class="d-flex justify-content-end">
                  <button type="button" class="btn btn-outline-danger me-2">Cancel</button>
                  <button type="submit" class="btn btn-primary">Submit</button>
              </div>
          </form>
      </div>
  </div>
</div>
