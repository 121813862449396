<div class="d-flex vh-100">
    <app-sidebar class="sidebar"></app-sidebar>

    <div class="flex-grow-1 overflow-auto p-4 main-content">
        <div class="card p-4">
            <h2 class="mb-4">Blog List</h2>

            <div class="m-4" id="blog-list-item">
                <div *ngFor="let item of items | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                    <div class="card mb-3 p-3">
                        <app-blog-list-item></app-blog-list-item>
                    </div>
                </div>

                <div class="pagination-container">
                    <button class="pagination-arrow" (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">
                        <i class="bi bi-chevron-left"></i>
                    </button>

                    <ng-container *ngFor="let page of pages; let i = index">
                        <div class="pagination-dot"
                             [class.active]="currentPage === i + 1"
                             (click)="onPageChange(i + 1)">
                        </div>
                    </ng-container>

                    <button class="pagination-arrow" (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">
                        <i class="bi bi-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
