import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ProductItemComponent } from './component/product-item/product-item.component';
import { ProductAttributes } from '../../core/models/typings.models';
import { ProductFacade } from './services/product-facade.service';
import { Subject, takeUntil } from 'rxjs';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ProductItemComponent,
    NgxPaginationModule,
  ],
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductsComponent implements OnInit, OnDestroy {
  products: ProductAttributes[] = [];
  filteredProducts: ProductAttributes[] = [];
  destroy$ = new Subject<void>();

  // Set up the filters, including those based on `typeID` values
  filters = [
    { label: 'Best Seller', count: 0, selected: false },
    { label: 'NEW', count: 0, selected: false },
    { label: 'Sterile', count: 0, selected: false },
    { label: 'Non Sterile', count: 0, selected: false },
    { label: 'Bandage', count: 0, selected: false },
    { label: 'Compresses', count: 0, selected: false },
    { label: 'Sparadraps', count: 0, selected: false },
    { label: 'All', count: 0, selected: false },
  ];

  selectedCount = 0; // New variable to track selected filter count

  itemsPerPage = 9;
  currentPage = 1;

  items = Array.from({ length: this.selectedCount }, (_, i) => `Item ${i + 1}`);

  constructor(private productFacade: ProductFacade) { }

  ngOnInit(): void {
    // this.getProductList();
  }

  // getProductList() {
  //   this.productFacade.loadProductList();
  //   this.fetchProducts();
  // }
  // onPageChange(page: number): void {
  //   this.currentPage = page;

  //   const element = document.getElementById('blog-list');
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //   }
  // }
  // fetchProducts() {
  //   this.productFacade
  //     .fetchProductList()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((productList: ProductAttributes[]) => {
  //       this.products = productList;
  //       this.updateFilterCounts();
  //       this.onFilterChange();
  //     });
  // }

  // updateFilterCounts() {
  //   const bestSellerCount = this.products.filter((p) => p.bestSeller).length;
  //   const newCount = this.products.filter((p) => p.isNew).length;
  //   const sterileCount = this.products.filter((p) => p.isSterile).length;
  //   const nonSterileCount = this.products.filter((p) => !p.isSterile).length;

  //   // Calculate counts based on `typeID` for specific product types
  //   const bandageCount = this.products.filter(
  //     (p) => p.typeID === 'tBandages'
  //   ).length;
  //   const compressesCount = this.products.filter(
  //     (p) => p.typeID === 'tCompresses'
  //   ).length;
  //   const dressingsCount = this.products.filter(
  //     (p) => p.typeID === 'tDressings'
  //   ).length;
  //   const plastersCount = this.products.filter(
  //     (p) => p.typeID === 'tPlasters'
  //   ).length;
  //   const othersCount = this.products.filter(
  //     (p) => p.typeID === 'tOthers'
  //   ).length;

  //   this.filters = [
  //     { label: 'Best Seller', count: bestSellerCount, selected: false },
  //     { label: 'NEW', count: newCount, selected: false },
  //     { label: 'Sterile', count: sterileCount, selected: false },
  //     { label: 'Non Sterile', count: nonSterileCount, selected: false },
  //     { label: 'Bandage', count: bandageCount, selected: false },
  //     { label: 'Compresses', count: compressesCount, selected: false },
  //     { label: 'Dressings', count: dressingsCount, selected: false },
  //     { label: 'Plasters', count: othersCount, selected: false },
  //     { label: 'Others', count: plastersCount, selected: false },
  //     { label: 'All', count: this.products.length, selected: false },
  //   ];
  // }

  // onFilterChange() {
  //   this.currentPage = 1;

  //   const selectedFilters = this.filters
  //     .filter((f) => f.selected)
  //     .map((f) => f.label);

  //   // If 'All' is selected or no filters are selected, show all products
  //   if (selectedFilters.includes('All') || selectedFilters.length === 0) {
  //     this.filteredProducts = [...this.products];
  //   } else {
  //     // Filter products based on the selected filters
  //     this.filteredProducts = this.products.filter((product) => {
  //       let matches = false;

  //       // Check if product matches any selected filter
  //       if (selectedFilters.includes('NEW') && product.isNew) {
  //         matches = true;
  //       }
  //       if (selectedFilters.includes('Best Seller') && product.bestSeller) {
  //         matches = true;
  //       }
  //       if (selectedFilters.includes('Sterile') && product.isSterile) {
  //         matches = true;
  //       }
  //       if (selectedFilters.includes('Non Sterile') && !product.isSterile) {
  //         matches = true;
  //       }
  //       if (
  //         selectedFilters.includes('Bandage') &&
  //         product.typeID === 'tBandages'
  //       ) {
  //         matches = true;
  //       }
  //       if (
  //         selectedFilters.includes('Compresses') &&
  //         product.typeID === 'tCompresses'
  //       ) {
  //         matches = true;
  //       }
  //       if (
  //         selectedFilters.includes('Dressings') &&
  //         product.typeID === 'tDressings'
  //       ) {
  //         matches = true;
  //       }
  //       if (
  //         selectedFilters.includes('Plasters') &&
  //         product.typeID === 'tPlasters'
  //       ) {
  //         matches = true;
  //       }
  //       if (
  //         selectedFilters.includes('Others') &&
  //         product.typeID === 'tOthers'
  //       ) {
  //         matches = true;
  //       }

  //       return matches;
  //     });
  //   }

  //   // Now, to count unique products (avoiding duplicates when a product matches multiple filters)
  //   // Create a set to store the unique matching products
  //   const uniqueProducts = new Set(
  //     this.filteredProducts.map((product) => product.id)
  //   );

  //   // Set the selectedCount to the size of the unique products set
  //   this.selectedCount = uniqueProducts.size;
  // }

  ngOnDestroy(): void {
    //   this.destroy$.next();
    //   this.destroy$.complete();
  }
}
