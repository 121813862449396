import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    constructor(private translate: TranslateService) { }

    setLanguage(language: string) {
        this.translate.use(language.toLowerCase());
        document.documentElement.setAttribute(
            'dir',
            language.toLowerCase() === 'ar' ? 'rtl' : 'ltr'
        );
    }

    addLanguages() {
        this.translate.addLangs(['fr', 'ar', 'en']);
    }

    getTranslation(): TranslateService {
        return this.translate;
    }
}
