import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { BlogListItemComponent } from "./blog-list-item/blog-list-item.component";
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-edit-blog',
  standalone: true,
  imports: [TranslateModule, SidebarComponent, BlogListItemComponent,NgxPaginationModule,CommonModule],
  templateUrl: './edit-blog.component.html',
  styleUrl: './edit-blog.component.scss'
})
export class EditBlogComponent {
  items = Array(185).fill(0);
  itemsPerPage = 10;
  currentPage = 1;
  totalPages: number = Math.ceil(this.items.length / this.itemsPerPage);
  pages = Array(this.totalPages).fill(0);

  onPageChange(page: number) {
      if (page >= 1 && page <= this.totalPages) {
          this.currentPage = page;
      }
  }
}
