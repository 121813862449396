import { AfterViewInit, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BlogShowcaseComponent } from '../blog/component/blog-showcase/blog-showcase.component';
import { NgFor } from '@angular/common';
import 'intersection-observer';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [TranslateModule, BlogShowcaseComponent, NgFor],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements AfterViewInit{
  contactMethods = [
    {
      email: 'marketing@aldismed.com',
      phone: '+1 234 567 890'
    },
    {
      email: 'commercial@aldismed.com',
      phone: '+1 234 567 890'
    },
    {
      email: 'direction@aldismed.com',
      phone: '+1 234 567 890'
    }
  ];
  ngAfterViewInit() {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) { 
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target); 
        }
      });
    }, { threshold: 0.5 }); 
    document.querySelectorAll('.page-element').forEach(element => {
      observer.observe(element);
    });
  } 
  }
  navigateToForm(): void {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSdnH0cKmdjaM9WJyv_FjZ09mZnA7UOc_jswkenQLBBrzP4Wbg/viewform', '_blank');
  }   
}
