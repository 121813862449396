<div class="fade-in-left">
  <div class="">
    <div class="fade-in-left bg-primary pt-5 pb-5 px-4 page-element">
      <div class="contact-container my-4 max-width-container">
        <h1 class="mb-2">{{ "contact_us" | translate }}</h1>
        <p>{{ "let_us_help_text" | translate }}</p>
      </div>
    </div>

    <div class="p-4">
      <div class="py-5 row row-cols-1 row-cols-md-2 g-4 max-width-container">
        <div class="col contact-info page-element">
          <h1>{{ "contact_us" | translate }}</h1>
          <div class="button-container mt-5 row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
              <button class="btn w-100">
                {{ "btn_rcl" | translate }}
              </button>
            </div>
            <div class="col">
              <button class="btn w-100" (click)="navigateToForm()">
                {{ "btn_sts" | translate }}
              </button>
            </div>            
          </div>
        </div>

        <div class="col contact-details page-element">
          <div class="find-us mb-2">
            <h3>{{ "find_us_title" | translate }}</h3>
            <div class="row">
              <div class="col-auto contact-icon">
                <img src="assets/icons/map-pin.svg" alt="map-pin svg" />
              </div>
              <div class="col">
                <span> {{ "find_us_body1" | translate }}</span>
              </div>
            </div>
          </div>
          <div class="reach-out mb-2">
            <h3>{{ "reach_out_title" | translate }}</h3>
            <div class="row row-cols-1">
              <div
                class="row mb-2 d-flex justify-content-evenly justify-content-md-start"
              >
                <div class="row">
                  <div class="col-auto contact-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="gray"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-device-landline-phone"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20 3h-2a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2z"
                      />
                      <path d="M16 4h-11a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h11" />
                      <path d="M12 8h-6v3h6z" />
                      <path d="M12 14v.01" />
                      <path d="M9 14v.01" />
                      <path d="M6 14v.01" />
                      <path d="M12 17v.01" />
                      <path d="M9 17v.01" />
                      <path d="M6 17v.01" />
                    </svg>
                  </div>
                  <div class="col">
                    <span>
                      {{ "phone" | translate }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="row mb-2 d-flex justify-content-evenly justify-content-md-start"
              >
                <div class="row">
                  <div class="col-auto contact-icon">
                    <img src="assets/icons/mail.svg" alt="Mail svg" />
                  </div>
                  <div class="col">
                    <span style="text-decoration: underline"
                      >marketing&#64;aldismed.com</span
                    >
                  </div>
                  <div class="row">
                    <div class="col-auto contact-icon">
                      <img src="assets/icons/phone.svg" alt="Phone svg" />
                    </div>
                    <div class="col">
                      <span> {{ "phone_marketing" | translate }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-2 d-flex justify-content-evenly justify-content-md-start"
                >
                  <div class="row">
                    <div class="col-auto contact-icon">
                      <img src="assets/icons/mail.svg" alt="Mail svg" />
                    </div>
                    <div class="col">
                      <span> commercial&#64;aldismed.com </span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-auto contact-icon">
                      <img src="assets/icons/phone.svg" alt="Phone svg" />
                    </div>
                    <div class="col">
                      <span>
                        {{ "phone_commercial" | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-2 d-flex justify-content-evenly justify-content-md-start"
                >
                  <div class="row">
                    <div class="col-auto contact-icon">
                      <img src="assets/icons/mail.svg" alt="Mail svg" />
                    </div>
                    <div class="col">
                      <span style="text-decoration: underline"
                        >direction.technique&#64;aldismed.com</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto contact-icon">
                      <img src="assets/icons/phone.svg" alt="Phone svg" />
                    </div>
                    <div class="col">
                      <span> {{ "phone_technique" | translate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="visit-section page-element">
        <div class="row row-cols-1 row-cols-md-2 g-4 max-width-container">
          <div class="col contact-info">
            <h1>
              {{ "Get_in_touch" | translate }}<br />
              {{ "with_us" | translate }}
            </h1>
            <p>
              {{ "Get_in_touch_text1" | translate }} <br />
              {{ "Get_in_touch_text2" | translate }}<br />
              {{ "Get_in_touch_text3" | translate }}
            </p>
          </div>

          <div class="visit-map col d-flex justify-content-start page-element">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.996511575557!2d5.665299637973705!3d36.11794273449124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f375c7024c1aef%3A0x7d7fb8609e36bfca!2sSARL%20ALDISMED!5e0!3m2!1sfr!2sdz!4v1731527971587!5m2!1sfr!2sdz"
              width="1000"
              height="600"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div class="justify-content-start page-element max-width-container">
        <img
          src="assets/images/aldsimed-map.png"
          alt="Location Map"
          class="centered-image"
        />
      </div>

      <!-- <app-blog-showcase></app-blog-showcase> -->
    </div>
  </div>
</div>
