import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductFacade } from '../../services/product-facade.service';
import { ProductAttributes } from '../../../../core/models/typings.models';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-product-details',
  standalone: true,
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  product: ProductAttributes = {} as ProductAttributes;
  destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private productFacade: ProductFacade
  ) {}

  ngOnInit(): void {
    const productId = this.route.snapshot.paramMap.get('id');
    if (productId) {
      this.productFacade.loadSelectedProduct(productId);
      this.productFacade
        .getProductDetail()
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: ProductAttributes) => {
          this.product = data;
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
