import { Component, Input, OnInit, Inject, PLATFORM_ID, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-image-slider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit, OnDestroy {
  @Input() slides: any[] = [];
  @Input() autoPlay = true;
  @Input() autoPlaySpeed = 4000;

  currentSlide = 0;
  slidePosition = 0;
  autoPlayInterval: any;
  isBrowser: boolean;
  private mutationObserver!: MutationObserver;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.setupLanguageDirectionObserver();

      if (this.autoPlay) {
        this.startAutoPlay();
      }
    }
  }

  ngOnDestroy() {
    this.stopAutoPlay();
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  setupLanguageDirectionObserver() {
    this.mutationObserver = new MutationObserver(() => {
      this.updateSlidePosition();
    });

    this.mutationObserver.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['dir'],
    });
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    this.updateSlidePosition();
  }

  updateSlidePosition() {
    const slideWidth = 100;
    this.slidePosition = -this.currentSlide * slideWidth;
    this.cdr.detectChanges();
  }

  startAutoPlay() {
    this.autoPlayInterval = setInterval(() => {
      this.nextSlide();
    }, this.autoPlaySpeed);
  }

  stopAutoPlay() {
    clearInterval(this.autoPlayInterval);
  }
}
