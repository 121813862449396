<div class="d-flex vh-100">
    <app-sidebar></app-sidebar>

    <div class="flex-grow-1 overflow-auto p-4 main-content">
        <div class="card p-4">
            <h2 class="mb-4">Add Product</h2>
            <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="description-tab" data-bs-toggle="tab" href="#description" role="tab">Français</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab">Anglais</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="additional-info-tab" data-bs-toggle="tab" href="#additional-info" role="tab">Arab</a>
                </li>
            </ul>

            <form>
                <div class="row mb-3">
                    <div class="col-12 col-md-4 mb-3 mb-md-0">
                        <label for="title" class="form-label">Titre</label>
                        <input type="text" class="form-control" id="title" placeholder="Entrer un titre">
                    </div>
                    <div class="col-12 col-md-4 mb-3 mb-md-0">
                        <label for="category1" class="form-label">Famille</label>
                        <select class="form-select" id="category1">
                            <option selected>Choose...</option>
                            <option>Category 1</option>
                            <option>Category 2</option>
                        </select>
                    </div>
                    <div class="col-12 col-md-4">
                        <label for="category2" class="form-label">Type (Sterile / non sterile)</label>
                        <select class="form-select" id="category2">
                            <option selected>Choose...</option>
                            <option>Category A</option>
                            <option>Category B</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-3 align-items-center">
                    <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
                        <div class="form-check me-3">
                            <input type="checkbox" class="form-check-input" id="bestSeller">
                            <label class="form-check-label" for="bestSeller">Best Seller</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="new">
                            <label class="form-check-label" for="new">New</label>
                        </div>
                    </div>

                    <div class="col-12 col-md-4 d-flex align-items-center mb-3 mb-md-0">
                        <label for="ficheTechnique" class="form-label me-2 flex-shrink-0 mt-2">Fiche Technique</label>
                        <input type="file" class="form-control flex-grow-1" id="ficheTechnique" accept=".pdf,.doc,.docx">
                    </div>

                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <label for="productPhotos" class="form-label me-2 flex-shrink-0 mt-2">Product Photos</label>
                        <input type="file" class="form-control flex-grow-1" id="productPhotos" accept="image/*" multiple>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="editor1" class="form-label-title font-weight-bold mb-4">Description générale</label>
                    <div id="editor1" style="height: 300px; background-color: #fff;"></div>
                </div>
                <div class="mb-3">
                    <label for="editor2" class="form-label-title font-weight-bold mb-4">Informations techniques</label>
                    <div id="editor2" style="height: 300px; background-color: #fff;"></div>
                </div>
                <div class="mb-3">
                    <label for="editor3" class="form-label-title font-weight-bold mb-4">Informations générales</label>
                    <div id="editor3" style="height: 300px; background-color: #fff;"></div>
                </div>                
            </form>
        </div>
    </div>
</div>
