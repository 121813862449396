import { AfterViewInit, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import 'intersection-observer';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent implements AfterViewInit{
  ngAfterViewInit() {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) { 
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target); 
        }
      });
    }, { threshold: 0.5 }); 
  
    document.querySelectorAll('.page-element').forEach(element => {
      observer.observe(element);
    });
  }
}    
}
