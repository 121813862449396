<div class="fade-in-left container my-5">
  <div class="max-width-container">
    <h1 class="page-element">
      {{ "Historique_title1" | translate }}
      <span class="smaller-text">
        ({{ "Historique_title2" | translate }})
      </span>
    </h1>
    <p class="Historique page-element">{{ "Historique" | translate }}</p>
    <img
      src="assets/images/DSC03255.jpg"
      alt="Aldismed production"
      class="aboutUs-image page-element"
    />

    <!-- Statistics Section -->
    <div class="row text-center mb-5">
      <div class="col-md-4 page-element">
        <h2 class="display-4">150+</h2>
        <p>{{ "Personnel" | translate }}</p>
      </div>
      <div class="col-md-4 page-element">
        <h2 class="display-4">10+</h2>
        <p>{{ "exp" | translate }}</p>
      </div>
      <div class="col-md-4 page-element">
        <h2 class="display-4">70+</h2>
        <p>{{ "Nbr_prd" | translate }}</p>
      </div>
    </div>

    <div class="row align-items-center mb-5">
      <div class="col-md-6 page-element">
        <div class="container">
          <!-- Add container for consistent width -->
          <h3 class="success-text">{{ "Notre_mission_title" | translate }}</h3>
          <p class="mt-2 mission-paragraph">
            {{ "Notre_mission_text1" | translate }}
          </p>
          <p class="mt-2 mission-paragraph">
            {{ "Notre_mission_text2_1" | translate }}
            <strong>{{ "Notre_mission_text2_2" | translate }}</strong>
            {{ "Notre_mission_text2_3" | translate }}
          </p>
          <p class="mt-2">
            {{ "Notre_mission_text3" | translate }}
          </p>
        </div>
      </div>
      <div class="col-md-6 page-element">
        <img
          src="assets/images/DSC03494.jpg"
          class="img-fluid rounded"
          alt="Mission Image"
          style="height: 500px; object-fit: cover"
        />
      </div>
    </div>

    <!-- Vision Section -->
    <div class="row align-items-center mb-5">
      <div class="col-md-6 page-element">
        <img
          src="assets/images/DSC03397.jpg"
          class="img-fluid rounded"
          alt="Vision Image"
        />
      </div>
      <div class="col-md-6 page-element">
        <h3 class="success-text">{{ "Notre_vision_title" | translate }}</h3>
        <p class="mt-2 vision-paragraph">
          {{ "Notre_vision_text1" | translate }}
        </p>
        <p class="mt-2 vision-paragraph">
          {{ "Notre_vision_text2" | translate }}
        </p>
      </div>
    </div>

    <!-- Values Section -->
    <div class="mb-5">
      <h3 class="success-text font-weight-bold page-element">
        {{ "Nos_valeurs" | translate }}
      </h3>
      <div class="row text-center mt-4">
        <div class="col-md-3 mb-3 page-element">
          <div class="value-card">
            <div class="icon-circle">
              <i class="bi bi-briefcase-fill"></i>
              <!-- Professionnalisme Icon -->
            </div>
            <div class="value-text">{{ "Professionnalisme" | translate }}</div>
          </div>
        </div>
        <div class="col-md-3 mb-3 page-element">
          <div class="value-card">
            <div class="icon-circle">
              <i class="bi bi-lightbulb-fill"></i>
              <!-- Innovation Icon -->
            </div>
            <div class="value-text">{{ "Innovation" | translate }}</div>
          </div>
        </div>
        <div class="col-md-3 mb-3 page-element">
          <div class="value-card">
            <div class="icon-circle">
              <i class="bi bi-shield-lock-fill"></i>
              <!-- Confiance Icon -->
            </div>
            <div class="value-text">{{ "Confiance" | translate }}</div>
          </div>
        </div>
        <div class="col-md-3 mb-3 page-element">
          <div class="value-card">
            <div class="icon-circle">
              <i class="bi bi-person-fill"></i>
              <!-- Valeur Client Icon -->
            </div>
            <div class="value-text">{{ "Valeur_client" | translate }}</div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="success-text page-element">
      {{ "production_title" | translate }}
    </h3>
    <ul class="mt-4">
      <li class="production-site page-element">
        {{ "production_li1" | translate }}
      </li>
      <li class="production-site page-element">
        {{ "production_li2" | translate }}
      </li>
      <li class="production-site page-element">
        {{ "production_li3" | translate }}.
      </li>
      <li class="production-site page-element">
        {{ "production_li4" | translate }}
      </li>
      <li class="production-site page-element">
        {{ "production_li5" | translate }}
      </li>
    </ul>
    <img
      src="assets/images/aboutUs.png"
      alt="Aldismed production"
      class="aboutUs-image page-element"
    />
    <!-- Certifications Section -->
    <div class="mb-5">
      <h3 class="success-text mt-2">{{ "F_r_title" | translate }}</h3>
      <ul class="mt-4">
        <li class="force">{{ "F_r_li1" | translate }}</li>
        <li class="force">{{ "F_r_li2" | translate }}</li>
        <li class="force">{{ "F_r_li3" | translate }}</li>
      </ul>
      <div class="row text-center mt-5">
        <div class="col-md-4">
          <figure>
            <img
              src="assets/images/certificate1.png"
              class="img-fluid"
              alt="Certificate GMP"
            />
          </figure>
          <img
            src="assets/images/GMP.png"
            class="img-fluid mt-4 mb-4"
            alt="GMP Logo"
          />
        </div>
        <div class="col-md-4">
          <figure>
            <img
              src="assets/images/certificate2.png"
              class="img-fluid"
              alt="Certificate ISO 13485"
            />
          </figure>
          <img
            src="assets/images/iso13485.png"
            class="img-fluid mt-5 mb-4"
            alt="ISO 13485 Logo"
          />
        </div>
        <div class="col-md-4">
          <figure>
            <img
              src="assets/images/certificate3.png"
              class="img-fluid"
              alt="Certificate ISO 9001"
            />
          </figure>
          <img
            src="assets/images/iso9001.png"
            class="img-fluid mt-5 mb-4"
            alt="ISO 9001 Logo"
          />
        </div>
      </div>
    </div>
  </div>
</div>
