import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterModule, Route } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../../core/services/LanguageService/language.service';
import { routes } from '../../app.config'; // Import the route configuration
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  showBreadcrumb: boolean = true;
  breadcrumbs: Array<{ id: string; label: string; url: string }> = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.updateBreadcrumbs();
        })
    );

    this.subscriptions.add(
      this.languageService.getTranslation().onLangChange.subscribe((event) => {
        this.handleLanguageChange(event.lang);
      })
    );
  }

  private updateBreadcrumbs(): void {
    this.breadcrumbs = [];
    this.createBreadcrumbs(this.activatedRoute.root);

    if (this.breadcrumbs.length) {
      this.translateBreadcrumbs();
    }
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = ''): void {
    const routeConfig = route.routeConfig;

    if (routeConfig?.data?.['breadcrumb']) {
      const path = routeConfig.path;

      if (path && path.includes('/:id')) {
        this.addHomeBreadcrumb();
        this.addParentBreadcrumb(path);

        // Add dynamic breadcrumb with `id`
        const id = route.snapshot.paramMap.get('id');
        if (id) {
          this.breadcrumbs.push({
            id: routeConfig.data['breadcrumb'],
            label: routeConfig.data['breadcrumb'],
            url: '',
          });
        }
      } else if (path === '') {
        this.addHomeBreadcrumb();
      } else {
        this.breadcrumbs.push(
          { id: this.getHomeLabel(), label: this.getHomeLabel(), url: '/' },
          {
            id: routeConfig.data['breadcrumb'],
            label: routeConfig.data['breadcrumb'],
            url: `${url}`,
          }
        );
      }
    }

    // Process child routes recursively
    route.children.forEach((child) => {
      const routeURL = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      const newUrl = `${url}/${routeURL}`;
      this.createBreadcrumbs(child, newUrl);
    });
  }

  private translateBreadcrumbs(): void {
    const translationSubscriptions = this.breadcrumbs.map((data) =>
      this.languageService
        .getTranslation()
        .stream(data.id)
        .subscribe((translation) => {
          const index = this.breadcrumbs.findIndex(
            (breadcrumb) => breadcrumb.id === data.id
          );
          if (index !== -1) {
            this.breadcrumbs[index].label = translation;
          }
        })
    );

    this.subscriptions.add(...translationSubscriptions);
  }

  private addHomeBreadcrumb(): void {
    this.breadcrumbs.push({
      id: this.getHomeLabel(),
      label: this.getHomeLabel(),
      url: '/',
    });
  }

  private addParentBreadcrumb(path: string): void {
    const parentPath = path.split('/:id')[0];
    const parentLabel = this.getParentLabel(parentPath);

    this.breadcrumbs.push({
      id: parentLabel,
      label: parentLabel,
      url: `/${parentPath}`,
    });
  }

  private getHomeLabel(): string {
    return routes.find((r: Route) => r.path === '')?.data?.breadcrumb || 'HOME';
  }

  private getParentLabel(parentPath: string): string {
    const parentRoute = routes.find((r: Route) => r.path === parentPath);
    return parentRoute?.data?.breadcrumb || parentPath.toUpperCase();
  }

  private handleLanguageChange(lang: string): void {
    if (lang === 'ar') {
      this.addRtlCss();
    } else {
      this.removeRtlCss();
    }
  }

  private addRtlCss(): void {
    const linkElement = document.head.querySelector(
      'link[rel="stylesheet"][href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css"]'
    );

    if (!linkElement) {
      const newLinkElement = this.renderer.createElement('link');
      this.renderer.setAttribute(newLinkElement, 'rel', 'stylesheet');
      this.renderer.setAttribute(
        newLinkElement,
        'href',
        'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css'
      );
      this.renderer.setAttribute(
        newLinkElement,
        'integrity',
        'sha384-gXt9imSW0VcJVHezoNQsP+TNrjYXoGcrqBZJpry9zJt8PCQjobwmhMGaDHTASo9N'
      );
      this.renderer.setAttribute(newLinkElement, 'crossorigin', 'anonymous');
      this.renderer.appendChild(document.head, newLinkElement);
    }

    this.renderer.setStyle(
      document.body,
      'font-family',
      '"Tajawal", sans-serif'
    );
  }

  private removeRtlCss(): void {
    const linkElement = document.head.querySelector(
      'link[rel="stylesheet"][href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css"]'
    );

    if (linkElement) {
      this.renderer.removeChild(document.head, linkElement);
    }

    this.renderer.removeStyle(document.body, 'font-family');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
