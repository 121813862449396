import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BlogItemComponent } from './component/blog-item/blog-item.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FontService } from '../../core/services/font-service';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    BlogItemComponent,
    NgxPaginationModule,
  ],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BlogComponent implements OnInit, AfterViewChecked, OnDestroy {
  constructor(private fontService: FontService) {}

  ngOnInit(): void {
    // this.fontService.applyGlobalFontClasses();
  }
  ngAfterViewChecked(): void {
    // this.fontService.applyGlobalFontClasses(); // Force font reapplication after view check
  }
  itemsPerPage = 9;
  currentPage = 1;
  totalItems = 100;

  items = Array.from({ length: this.totalItems }, (_, i) => `Item ${i + 1}`);
  onPageChange(page: number): void {
    this.currentPage = page;

    const element = document.getElementById('blog-list');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  ngOnDestroy(): void {}
}
