import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToolbarComponent } from './modules/toolbar/toolbar.component';
import { LinksAndProductsComponent } from './modules/links-and-products/links-and-products.component';
import { Router, NavigationEnd } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { BreadcrumbComponent } from './modules/breadcrumb/breadcrumb.component';
import { LanguageService } from './core/services/LanguageService/language.service';
import { FontService } from './core/services/font-service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToolbarComponent,
    LinksAndProductsComponent,
    CommonModule,
    NgxPaginationModule,
    BreadcrumbComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'aldismed-web-app';
  showFooter: boolean = true;
  showNavbar: boolean = true;

  @ViewChild(BreadcrumbComponent) breadcrumbComponent!: BreadcrumbComponent;

  private hiddenFooterRoutes: string[] = [
    '/login',
    '/bloglist',
    '/productlist',
    '/addproduct',
    '/addblog',
  ];
  private hiddenNavbarRoutes: string[] = [
    '/login',
    '/bloglist',
    '/productlist',
    '/addproduct',
    '/addblog',
  ];
  private hiddenBreadcrumbRoutes: string[] = ['/', '/login', '/blog'];

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private fontService: FontService
  ) {

    this.languageService.getTranslation().onLangChange.subscribe((event) => {
      if (event.lang === 'ar') {
        this.fontService.applyRtlFont();
      } else {
        this.fontService.applyLtrFont();
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.adjustVisibility(event);
      }
      if (this.languageService.getTranslation().currentLang === 'ar') {
        this.fontService.applyRtlFont();
      } else {
        this.fontService.applyLtrFont();
      }
    });
  }

  private adjustVisibility(event: NavigationEnd) {
    this.showFooter = !this.hiddenFooterRoutes.some(
      (route) => event.urlAfterRedirects === route
    );
    this.updateBreadcrumbVisibility(event.urlAfterRedirects);
    this.showNavbar = !this.hiddenNavbarRoutes.some((route) =>
      event.url.startsWith(route)
    );
  }

  private updateBreadcrumbVisibility(url: string) {
    let shouldHideBreadcrumb = this.hiddenBreadcrumbRoutes.includes(url);

    if (this.breadcrumbComponent) {
      this.breadcrumbComponent.showBreadcrumb = !shouldHideBreadcrumb;
    }
  }
}
