import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog-list-item',
  standalone: true,
  imports: [],
  templateUrl: './blog-list-item.component.html',
  styleUrl: './blog-list-item.component.scss'
})
export class BlogListItemComponent {
  constructor(private router: Router) {}
  navigateToEditBlog(){
 this.router.navigate(['/addblog']);
  }
}
