import { ComponentRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

export class DialogRef<T = any> {
  componentRef!: ComponentRef<T>;

  readonly onBackdropClick: Observable<any> | undefined;
  protected onClose$: Subject<any> = new Subject();
  readonly onClose: Observable<T> = this.onClose$.asObservable();

  constructor(public overlayRef: OverlayRef) {}

  closeModal(res?: any) {
    if (this.overlayRef) {
      this.onClose$.next(res);
      this.onClose$.complete();
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }
}
