<div class="container mt-4">
  <div class="container">
    <div class="row row-cols-1 row-cols-md-2 g-4">
      <!-- Product Image Section -->
      <div class="col">
        <div class="card product-image">
          <img
            src="assets/images/Purifié-5-side.png"
            alt="Product Image"
            class="img-fluid w-100"
            style="max-width: 1000px"
          />
        </div>
      </div>

      <!-- Product Overview Section -->
      <div class="col product-overview">
        <h1 class="mb-4 product-title">
          {{ product.lanEN.title }}
        </h1>
        <div class="mb-4">
          <a href="#" class="btn btn-primary btn-sm"
            >Download technical sheet</a
          >
          <p class="mt-4">
            <strong>REFERENCE: {{ product.reference }}</strong>
          </p>
          <p>
            <strong>CATEGORIES: {{ product.lanEN.type }}</strong>
          </p>
        </div>
        <p>
          {{ product.lanEN.description }}
        </p>

        <div class="product-details mt-4">
          <div class="row">
            <div class="col-12 col-md-6 mx-auto">
              <div class="row align-items-center">
                <div class="col"><strong>Raw Material</strong></div>
                <div class="col">Cotton</div>
              </div>
              <hr />
            </div>

            <div class="col-12 col-md-6 mx-auto">
              <div class="row align-items-center">
                <div class="col"><strong>Type</strong></div>
                <div class="col">Purified</div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container mt-4">
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item">
      <a
        class="nav-link active"
        id="description-tab"
        data-bs-toggle="tab"
        href="#description"
        role="tab"
        >Description & Dimensions</a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        id="additional-info-tab"
        data-bs-toggle="tab"
        href="#additional-info"
        role="tab"
        >Additional Information</a
      >
    </li>
  </ul>

  <div class="tab-content mt-3" id="myTabContent">
    <div class="tab-pane fade show active" id="description" role="tabpanel">
      <h3>How to use it?</h3>
      <p>
        Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in
        orci risus.
      </p>
      <h3>What it is used for?</h3>
      <p>
        Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in
        orci risus.
      </p>

      <h3>Properties</h3>
      <ul>
        <li>Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat.</li>
        <li>Morbi in orci risus. Donec pretium fringilla blandit.</li>
        <li>Vivamus dictum ut erat nec congue.</li>
      </ul>

      <div
        class="row row-cols-1 row-cols-md-2 d-flex justify-content-center align-items-start g-2"
      >
        <div class="col-lg-4 col-md-12">
          <h3 class="mb-3 dimension">Dimensions & Type</h3>
          <div class="product-dimensions mt-4">
            <table class="table table-borderless mx-auto">
              <tbody>
                <tr>
                  <td><strong>General Dimensions</strong></td>
                  <td>52" L x 16.5" W x 24" H</td>
                </tr>
                <tr>
                  <td><strong>Interior Dimensions</strong></td>
                  <td>25" L x 15" W x 9" H</td>
                </tr>
                <tr>
                  <td><strong>Leg Height</strong></td>
                  <td>13"</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-8 col-md-12">
          <div
            class="row row-cols-1 row-cols-md-2 d-flex justify-content-center align-items-start g-4 m-0"
          >
            <div class="col">
              <div class="card product-image">
                <img
                  src="assets/images/item-product-description.png"
                  alt="Product Roll 1"
                  class="img-fluid w-100"
                />
              </div>
            </div>
            <div class="col">
              <div class="card product-image">
                <img
                  src="assets/images/item-product-description.png"
                  alt="Product Roll 1"
                  class="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane fade" id="additional-info" role="tabpanel">
      <h3>How to use it?</h3>
      <p>
        Donec sed erat ut magna suscipit mattis. Aliquam erat volutpat. Morbi in
        orci risus. Donec pretium fringilla blandit.
      </p>
    </div>
  </div>
</div>

<div class="text-center mt-4 mb-4">
  <a href="/products" class="btn btn-outline-custom">
    <span>&larr;</span> Back to Products
  </a>
</div>
